import React, { memo, Suspense } from 'react'
import PageLoading from '@/components/PageLoading'
import { useRoutes } from 'react-router-dom'
import routes from '@/router'
import { Toaster } from 'react-hot-toast'
import Bind from '@/views/Home/components/Bind'
import { Mask } from 'antd-mobile'
import useAppStore from '@/store/app'
const Layout = () => {
  const { isBind } = useAppStore()
  return (
    <div className={''}>
      <div className={''}>
        <Suspense fallback={<PageLoading />}>{useRoutes(routes)}</Suspense>
      </div>
      <Toaster />
      <Mask visible={!isBind} className={'overflow-y-scroll pb-[100px]'}>
        <Bind></Bind>
      </Mask>
    </div>
  )
}

export default memo(Layout)
