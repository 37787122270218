import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// import LanguageDetector from 'i18next-browser-languagedetector'
// 语言包
import zh from './zh.json'
import en from './en.json'
import vi from './vi.json'
import ko from './ko.json'
i18n
  // .use(LanguageDetector) 浏览器默认语言
  .use(initReactI18next)
  .init({
    lng: navigator.language,
    fallbackLng: 'en',
    resources: {
      en: {
        translation: en,
      },
      zh: {
        translation: zh,
      },
      vi: {
        translation: vi,
      },
      ko: {
        translation: ko,
      },
    },
    interpolation: {
      escapeValue: false,
    },
    // i18nextLng
    detection: {
      caches: ['localStorage', 'sessionStorage', 'cookie'],
    },
  })
