import React, { memo, useState } from 'react'
import { Image, Input } from 'antd-mobile'
import { useWeb3React } from '@web3-react/core'
import useAppStore from '@/store/app'
import { useTranslation } from 'react-i18next'
import { injected } from '@/web3/connectors'
import { changeNetwork } from '@/web3'
import { getQueryString } from '@/utils'
import toast from 'react-hot-toast'
const CHAINID = Number(process.env.REACT_APP_CHAIN_ID)

const Bind: React.FC = () => {
  const { i18n, t } = useTranslation()
  const [inputValue, setInputValue] = useState('')
  const { activate, chainId, account, deactivate } = useWeb3React()
  const { getConfig, appInit } = useAppStore()
  const confirm = async () => {
    if (inputValue === '') {
      toast.error(t('yao-qing-lian-jie-bu-neng-wei-kong'))
      return
    }
    await activate(
      injected,
      (err) => {
        console.error(err)
        changeNetwork(CHAINID)
      },
      false,
    ).then(() => {
      if (chainId !== CHAINID) {
        changeNetwork(CHAINID)
      }
    })
    const url = new URL(inputValue)
    const searchParams = new URLSearchParams(url.search)
    // 获取code参数的值
    const code = searchParams.get('code')
    await appInit(account as string, code as string, t, deactivate)
  }
  return (
    <div
      className={
        'mx-auto mt-[200px] box-border w-[280px] rounded-[12px] bg-white px-[16px] py-[20px]'
      }
    >
      <div className={'mb-[20px] text-center text-[16px] font-medium text-[#222222]'}>
        {t('bang-ding-yao-qing-lian-jie')}
      </div>
      <div
        className={
          'mb-[8px] mt-[15px] flex h-[44px] items-center rounded-[4px] bg-[#F1F3FF] px-[12px]'
        }
      >
        <Input value={inputValue} onChange={(val) => setInputValue(val)}></Input>
      </div>
      <div
        onClick={confirm}
        className={
          'mt-[29px] flex h-[44px] items-center justify-center rounded-[4px] bg-[#3EB978] text-[14px] font-normal text-white'
        }
      >
        {t('que-ding')}
      </div>
    </div>
  )
}

export default memo(Bind)
