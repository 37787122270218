import request from './index'

// 登录
export function apiLogin<T>(params: {
  referee?: string
  user_address?: string
}): Promise<Response<LoginResponse>> {
  return request.post({
    url: '/user/login/register',
    params,
  })
}

export function apiAdd<T>(): Promise<Response<AddResponse>> {
  return request.post({
    url: '/news/news/ad',
  })
}

export function apiGetCoin<T>(): Promise<Response<CoinResponse>> {
  return request.post({
    url: '/Invest/Invest_Pledge/getCoin',
  })
}

export function apiGetMachineList<T>(params: {
  page: number
  limit: number
}): Promise<Response<MachineResponse>> {
  return request.post({
    url: '/Invest/Invest_Pledge/getMachineList',
    params,
  })
}

export function apiGetMinerList<T>(params: {
  order_id?: number
  page: number
  limit: number
}): Promise<Response<MinerDetailResponse>> {
  return request.post({
    url: '/Invest/Invest_Pledge/getOrderList',
    params,
  })
}

export function apiGetMinerTest<T>(): Promise<Response<MinerListResponse>> {
  return request.post({
    url: '/Invest/Invest_Pledge/getMinerList',
  })
}

export function apiGetProductList<T>(): Promise<Response<ProductResponse[]>> {
  return request.post({
    url: '/Invest/Invest_Pledge/product',
  })
}

export function apiGetTeamInfo<T>(): Promise<Response<TeamInfoResponse>> {
  return request.post({
    url: '/user/Team/index',
  })
}

export function apiGetTeamList<T>(params: {
  page: number
  limit: number
}): Promise<Response<ListResponse<TeamListResponse>>> {
  return request.post({
    url: '/user/Team/lists',
    params,
  })
}

export function apiGetAssetsInfo<T>(params: {
  chain: number
}): Promise<Response<AssetsInfoResponse>> {
  return request.post({
    url: '/User/User/index',
    params,
  })
}

export function apiGetAssetsLog<T>(params: {
  page: number
  limit: number
}): Promise<Response<ListResponse<AssetsLogResponse>>> {
  return request.post({
    url: '/user/user/money_log',
    params,
  })
}

export function apiWithdraw<T>(params: {
  money: string
  money_type: number
  chain: number
}): Promise<Response<ListResponse<AssetsLogResponse>>> {
  return request.post({
    url: '/user/Withdraw/atm',
    params,
  })
}

export function apiPledgeLogs<T>(params: {
  page: number
  limit: number
}): Promise<Response<ListResponse<OrderItemResponse>>> {
  return request.post({
    url: '/Invest/Invest_Pledge/getOrderList',
    params,
  })
}

export function apiOrderCreate<T>(params: {
  order_money: string
}): Promise<Response<ListResponse<AssetsLogResponse>>> {
  return request.post({
    url: '/invest/Invest_Pledge/orderCreate',
    params,
  })
}

export function apiGetFuel<T>(): Promise<Response<FuelResponse>> {
  return request.post({
    url: '/invest/Invest_Pledge/getFuel',
  })
}
