import React, { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { changeNetwork } from '@/web3'
import Loading from '@/components/Loading/Loading'
import Layout from '@/layout'
import { injected } from '@/web3/connectors'
import { LoadingProvider } from '@/components/Loading/LoadingContext'
import { useTranslation } from 'react-i18next'
import useAppStore from '@/store/app'
import { getQueryString } from '@/utils'
const CHAINID = Number(process.env.REACT_APP_CHAIN_ID)

function App() {
  const { i18n, t } = useTranslation()
  const { activate, chainId, account, deactivate } = useWeb3React()
  const [code, setCode] = useState('')
  const { getConfig, appInit } = useAppStore()
  useEffect(() => {
    getConfig()
    i18n.changeLanguage(window.localStorage.getItem('i18nextLng') || 'en')
    window.localStorage.setItem('i18nextLng', i18n.resolvedLanguage)
  }, [])
  // 链接钱包
  useEffect(() => {
    activate(
      injected,
      (err) => {
        console.error(err)
        changeNetwork(CHAINID)
      },
      false,
    ).then(() => {
      if (chainId !== CHAINID) {
        changeNetwork(CHAINID)
      }
    })
  }, [chainId])
  // 监听切换

  const handleChainChanged = async () => {
    console.log('网络切换监听')
    await activate(injected, undefined, true).catch((error) => {
      console.error('链改变后链接钱包失败', error)
    })
    await changeNetwork(CHAINID)
  }

  const handleAccountsChanged = (accounts: string[]) => {
    console.log('钱包切换监听')
    if (accounts.length > 0) {
      activate(injected, undefined, true).catch((error) => {
        console.error('钱包变更后链接钱包失败', error)
      })
    }
  }

  window.ethereum.on('chainChanged', handleChainChanged)
  window.ethereum.on('accountsChanged', handleAccountsChanged)
  // 钱包切换刷新
  useEffect(() => {
    const value = getQueryString('code')
    if (value) {
      setCode(value)
    }
  }, [location])
  useEffect(() => {
    window.ethereum.on('accountsChanged', () => {
      window.location.reload()
    })
    if (account !== '' && account !== undefined) {
      window.localStorage.setItem('address', account as string)
      appInit(account as string, code, t, deactivate)
    }

    // getConfig()
  }, [account, chainId])
  return (
    <LoadingProvider>
      <Layout />
      <Loading />
    </LoadingProvider>
  )
}

export default App
