import React, { lazy } from 'react'
import type { RouteObject } from 'react-router-dom'

const Home = lazy(() => import('@/views/Home'))
const PledgeDetail = lazy(() => import('@/views/Home/pledgeDetail'))
const Detail = lazy(() => import('@/views/Home/detail'))
const Assets = lazy(() => import('@/views/Assets'))
const Mine = lazy(() => import('@/views/Mine'))
const NoFound = lazy(() => import('@/views/NoFound'))
const routes: RouteObject[] = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/pledge/detail/:id',
    element: <PledgeDetail />,
  },
  {
    path: '/detail',
    element: <Detail />,
  },
  {
    path: '/assets',
    element: <Assets />,
  },
  {
    path: '/mine',
    element: <Mine />,
  },
  {
    path: '*',
    element: <NoFound />,
  },
]

export default routes
