import { create } from 'zustand'
import * as api from '@/request/api'
import toast from 'react-hot-toast'
import { apiAdd } from '@/request/api'

const useAppStore = create<AppState>()((set, get) => ({
  token: '',
  address: '',
  loginStatus: false,
  isBind: true,
  userInfo: null,
  configInfo: null,
  appInit: async (address, code, t, deactivate) => {
    try {
      set({ address: address })
      const res = await api.apiLogin({
        referee: code,
        user_address: address,
      })
      set({ loginStatus: true })
      set({ isBind: true })
      set({ userInfo: res.data })
      window.localStorage.setItem('token', res.data.token)
      toast.success(res.msg)
      // get().getConfig()
    } catch (err: any) {
      console.log(err)
      toast.error(err.msg)
      if (err.code === -2) {
        set({ isBind: false })
      }
      set({ loginStatus: false })
      // deactivate()
      window.localStorage.clear()
    }
  },
  getConfig: async () => {
    try {
      const res = await apiAdd()
      set({ configInfo: res.data })
    } catch (e: any) {
      console.log(e)
    }
  },
}))

export default useAppStore
